<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader class="text-center bg-dark text-white">
          <b>{{ $t('label.listScale') }}</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
             <CCol sm="12" class="d-flex justify-content-end">
              <CButton
                shape="square"
                color="add"
                v-c-tooltip="{placement:'top-end', content: $t('label.Scale')}"
                class="d-flex align-items-center"
                @click="showModalCreateScale"
              >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{ $t('label.nueva') }}</span>
              </CButton>
            </CCol>
            <CCol sm="12">
              <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                column-filter
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items="formatedItems"
                :fields="fields"
                :loading="isLoading"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="5"
                :active-page="activePage"
                pagination
              >
                <template #loading>
                    <loading/>
                </template>
                <template #RowNumber="{ item }">
                  <td class="center-cell">
                    {{ item.RowNumber }}
                  </td>
                </template>
                <template #Status="{item}">
                  <td class="text-center">
                    <div>
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                    </div>
                  </td>
                </template>

                <template #Details="{item, index}">
                  <td class="text-center">
                      <CButton
                        shape="square"
                        color="edit"
                        size="sm"
                        v-c-tooltip="{
                          content: $t('label.edit')+' '+$t('label.Scale'),
                          placement: 'top-end'
                        }"
                        @click="showModalEditScale(item, index)"
                      >
                        <CIcon name="pencil" />
                      </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    
   <modal-create-bascula :modal.sync="modalAdd" @submited="handleSubmit"/>
   <modal-edit-bascula :modal.sync="modalEdit"  :scaleData="ScaleSelected" @submited="handleSubmit"/>
  </CRow>
</template>
<style>
    .icon{
        background-color: null; 
        border: 0px;
    }
</style>
<script>

import { mapState } from "vuex";
import { DateFormater } from '@/_helpers/funciones';
import modalCreateBascula  from './modal-create-bascula';
import modalEditBascula from './modal-edit-bascula';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
import GeneralMixin from '@/_mixins/general';


function  fields(){
  
 return [
    { 
      key: 'Nro', 
      label: '#',
      _style: 'width:30px;'
      ,filter:false,      
    },
    { key: 'TruckWeghingScaleName', label: this.$t('label.Scale'), _style: 'width:100px;'},
    { key: 'MaxWeightKgm', label: this.$t('label.MaxWeight')+' (KGM)',_style: 'width:140px;'},
    { key: 'MaxWeightTon', label: this.$t('label.MaxWeight')+' (TON)',_style: 'width:140px;'},
    { key: 'Ultimo', label:this.$t('label.user'), _style: 'width:100px;' },
    { key: 'FormatedDate', label:this.$t('label.date'),_classes:"text-center center-cell",_style:'width:100px;' },
    { key: 'Status', label:this.$t('label.status'),_classes:"text-center center-cell",_style:'width:50px;' },
    { 
      key: 'Details',
      label: '',
      _style:'width:50px;',      
      _classes:'center-cell',
      sorter: false,
      filter: false      
    }
  ];
}
function data() {
  return {
    items: [],
    // fields: fields,
    ScaleSelected: {},
    activePage: 1,
    isLoading: false,
    modalAdd: false,
    modalEdit: false,
  };
}

//*****Funcion created() del ciclo.
function created() {
  this.getBasculas();
}

function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}

//*****Funciones de methods:
function getBasculas() {
  this.isLoading = true;

  this.$http.get('TruckWeighingScale-list', { CompanyBranchId: this.getBranchId })
  .then(response => {
    this.items = response.data.data;

  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}

function showModalCreateScale() {
  this.modalAdd = true;
}
function showModalEditScale(item, index) {
  this.ScaleSelected = Object.assign({}, item);

  this.modalEdit = true;

}
function handleSubmit(){
  this.getBasculas();
}

//*****Funcones de computed:
function formatedItems() {

  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    Ultimo: item.TransaLogin ? item.TransaLogin : 'N/A',
    MaxWeightKgm: formatMilDecimal(item.MaxWeightKgm.toFixed(4)),
    MaxWeightTon: formatMilDecimal(item.MaxWeightTon.toFixed(4)),
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}

export default {
  name: 'bascula',
  mixins: [GeneralMixin],
  components: { modalCreateBascula, modalEditBascula},
  data,
  created,
  methods: {
    getBasculas,
    showModalCreateScale,
    showModalEditScale,
    handleSubmit
  },
  computed: {
    formatedItems,
    fields,
    getBranchId,
    ...mapState({
      user: (state) => state.auth.user,
      branch: (state) => state.auth.branch,
    }),
  }
}
</script>

<style scoped>
.label-scale{
  width: 4em;
  height: 4em;
}
.center-cell {
  text-align: center;
  vertical-align: middle;
}
</style>